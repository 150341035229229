import React from 'react';
import './App.css';
import Gallery from 'react-grid-gallery';

function App() {
    const images = [];
    for (let i = 1; i <= 400; i++) {
        images.push({
            src: `/images/${i}.jpg`,
            thumbnail: `/images/${i}_n.jpg`,
            thumbnailWidth: 320,
            thumbnailHeight: 214,
            caption: `Pilt ${i}`
        });
    }
    return (
        <div className="App">
            <Gallery images={images}/>
        </div>
    );
}

export default App;
